a, abbr, acronym, address, applet, big, blockquote, body, caption, cite, code, dd, del, dfn, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, iframe, img, ins, kbd, label, legend, li, menu, object, ol, p, pre, q, s, samp, small, strike, strong, sub, sup, table, tbody, td, tfoot, th, thead, tr, tt, ul, var {
    vertical-align: baseline;
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0
}

:focus {
    outline: 0
}

body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  line-height: 1;
  position: relative;
}

ol, ul {
    list-style: none
}

table {
    border-spacing: 0;
    border-collapse: separate
}

caption, td, th {
    font-weight: 400;
    text-align: left
}

blockquote:after, blockquote:before, q:after, q:before {
    content: ""
}

blockquote, q {
    quotes: "" ""
}

button, input {
    border: 0
}

button::-moz-focus-inner, input::-moz-focus-inner {
    padding: 0;
    border: 0
}

button, input, select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

img {
    max-width: 100%;
    vertical-align: middle;
}
a {
    pointer-events: auto!important;
}
a, a:hover {
    text-decoration: none
}

* {
    box-sizing: border-box
}
