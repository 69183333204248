@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  font-display: swap;
  src: url('../fonts/Open-Sans.woff2') format('woff2'),
       url('../fonts/Open-Sans.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src: url('../fonts/Open-Sans.woff2') format('woff2'),
  url('../fonts/Open-Sans.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src: url('../fonts/Open-Sans.woff2') format('woff2'),
  url('../fonts/Open-Sans.woff') format('woff');
}

@import "vars";
@import "reset";
@import "helpers";
@import "header";
@import "intro";
@import "footer";
@import "locale-switcher";

html, body{
  font-family: 'Open Sans', sans-serif;
  background: $main-bg;
  min-width: 360px;
}
body {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #3b455c #00385d;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-bottom: 2px;
    border-radius: 4px;
    background-color: #00385d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(122, 137, 174, .6);
  }
}

