.LanguageSelector {

  &-lang {
    position: relative;
    z-index: 1;
  }

  &-view {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    min-width: 240px;
    max-width: 100%;
    padding: 10px 16px;
    cursor: pointer;
    background-color: #082648;
    border-radius: 6px;
    font-size: 12px;
    line-height: 24px;
  }

  &-viewIsOpen {
    z-index: 1;
    border-radius:  0 0 6px 6px;

    + .LanguageSelector-dropdown {
      display: block;
    }
    .LanguageSelector-icon {
      transform: rotate(180deg);
    }
  }

  &-current {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #fff;
    line-height: 1;
    margin-right: 15px;
  }

  &-dropdown {
    border-radius: 6px 6px 0 0;
    display: none;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    scrollbar-color: #3b455c #00385d;
    scrollbar-width: thin;
    width: 100%;
    z-index: 2;
    background-color: #082648;
  }

  &-dropdownTop {
    top: unset;
    bottom: 40px;
  }

  &-dropdownBottom {
    top: 0;
    bottom: unset;
    padding-top: 40px;
    padding-bottom: 10px;
    border-width: 0 1px 1px;
    border-radius: 20px 20px 4px 4px;
  }
}

.CountryList-listItem {
  padding: 8px 16px;
  background-color: #082648;
  font-size: 12px;
  line-height: 24px;
  text-align: left;
  color: #fff;
  cursor: pointer;

}

.CountryList-listItemCurrent, .CountryList-listItem:hover {
  background: #094d73;
}

.LanguageSelector {
  color: #fff;
}

.LanguageSelector-icon {
  transition: transform .3s ease;
}
