@import "vars";
.text-big {
  font-size: 5em!important;
  @media (max-width: 768px) {
    font-size: 3em!important;
  }
}

.m-x-auto {
  margin: auto;
}
.text-center {
  text-align: center;
}
.d-block {
  display: block;
}
.w-100 {
  width: 100%;
}
.d-680-none {
  @media (max-width: 680px) {
    display: none;
  }
}

@keyframes spin-rotate {
  0% {
    animation-timing-function: cubic-bezier(0.5856,0.0703,0.4143,0.9297);
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn {
  color: $main-white;
  width: 381px;
  height: 52px;
  padding: 15px 0 17px 0;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  background-image: linear-gradient(to right, #ec0766 0%, #743090 100%);
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  font-size: 18px;
  font-weight: normal;
  justify-content: center;
  position: relative;
  text-align: center;
  text-indent: 1px;
  text-transform: none;
  z-index: 0;
  &.loading {
    &[data-clicked="clicked"] {
      cursor: default;
      overflow: hidden;
      &:before {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: block;
        content: '';
        background: rgba(#fff, .5);
        z-index: 1;
      }
      &:after {
        content: "";
        display: block;
        box-sizing: border-box;
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border-left: 5px solid #5f2776;
        border-right: 5px solid #5f2776;
        border-top: 5px solid transparent;
        border-bottom: 5px solid #5f2776;
        z-index: 2;
        animation: spin-rotate .8s infinite;
      }
    }
  }
  &:hover {
    background-image: linear-gradient(90deg,#c20554 0,#5f2776);
  }

  @media (max-width: 680px)  {
    width: 100%;
    height: 52px;
    padding: 15px;
  }
}
.link {
  color: $main-blue;
}
.gradient {
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 50px;
  background: linear-gradient(180deg, rgba(1, 5, 35, 0.0001) 0%, #010523 100%);

  &_vertical {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    background: linear-gradient(90.25deg, #010C26 10.05%, rgba(1, 12, 38, 0.88) 43.05%, rgba(1, 12, 38, 0) 94.97%);
  }
}
