.header {
  position: absolute;
  display: none;
  padding: 16px 45px;
  left: 0;
  right: 0;
  z-index: 1;
  @media (max-width: 680px) {
    display: block;
  }
}
