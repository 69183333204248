@import "vars";
.intro {
  position: relative;
  background-image: url("../images/intro/bg-desc.jpg");
  background-size: cover;
  background-position: 100% 50%;
  height: 1080px;
  color: $main-white;

  @media (max-width: 1680px) {
    height: 1024px;
  }
  @media (max-width: 1440px) {
    height: 810px;
  }
  @media (max-width: 1366px) {
    height: 768px;
  }
  @media (max-width: 1200px) {
    background-image: url("../images/intro/bg-tablet.jpg");
  }
  @media (max-width: 1024px) {
    background-position: 85% 50%;
  }
  @media (max-width: 680px) {
    background: none;
    height: auto;
  }

  &__mob-pic {
    position: relative;
    display: none;
    @media (max-width: 680px) {
      display: block;
    }
  }

  &__inner {
    position: relative;
    width: 100%;
    max-width: 1640px;
    height: 100%;
    margin: auto;
    padding: 184px 40px 0 40px;
    @media (max-width: 1440px) {
      padding-top: 86px;
    }
    @media (max-width: 1200px) {
      padding: 70px 32px 0 32px;
    }
    @media (max-width: 680px) {
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 39px;
    line-height: 48px;
    color: $main-blue;

    span {
      font-size: 54px;
      line-height: 72px;
    }

    @media (max-width: 680px) {
      font-size: 28px;
      line-height: 39px;

      span {
        font-size: 50px;
        line-height: 70px;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;

    &:after {
      content: '';
      position: absolute;
      left: 150%;
      top: 45%;
      width: 190px;
      height: 390px;
      transform: translateY(-45%);
      background: url("../images/intro/arrow_element.svg") center no-repeat;
      background-size: contain;

      @media (max-width: 1680px) {
        left: 35vw;
      }
      @media (max-width: 1200px) {
        display: none;
      }
    }
  }

  .content-block {
    align-items: center;
    max-width: 430px;

    @media (max-width: 680px) {
      max-width: none;
      justify-content: center;
      padding: 16px;
      &__top {
        display: none;
      }
    }

    &__top {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;

      img {
        margin-bottom: 30px;
      }

      small {
        font-weight: 300;
        font-size: 18px;
        line-height: 18px;
      }
    }

    &__center {
      width: 100%;
      margin-bottom: 24px;

      &.en {
        .intro__title {
          font-size: 47px;
          line-height: 48px;

          @media (max-width: 680px) {
            font-size: 28px;
            line-height: 39px;

            span {
              font-size: 50px;
              line-height: 70px;
            }
          }
        }
      }

      p {
        max-width: 380px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
      }
    }

    &__bottom {
        width: 100%;
    }
  }

  &__attention {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    padding: 24px 0;
    color: $other-blue;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    img {
      margin-right: 12px;
    }
  }

  &__link {
    color: $other-blue;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
