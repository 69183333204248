.footer {
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 16px 16px 0;
  text-align: center;
}

.footer-menu {
  padding: 13px 0;
  border-top: 1px solid #1a243c;
  text-align: center;
}

@media (min-width: 744px) {
  .footer-menu {
    border-top: 0;
  }
}

@media (min-width: 1000px) {
  .footer-menu {
    padding: 18px 0;
  }
}

@media (min-width: 1416px) {
  .footer-menu {
    padding: 29px 0;
    padding: 42px 0;
  }
}

.footer-menu__item {
  display: block;
  margin: 0 12px;
  font: 14px/24px open-sans-regular, sans-serif;
}

.footer-menu__item,
.footer-menu__item:active,
.footer-menu__item:link,
.footer-menu__item:visited,
.footer__link {
  color: #a3acc1;
}

.footer-menu__item:hover,
.footer__link:hover {
  color: #00badc;
}
@media (min-width: 320px) {
  .footer-menu__item {
    height: 48px;
  }
}
@media (min-width: 576px) {
  .footer-menu__item {
    display: inline-block;
    margin: 4px 12px;
  }
}

@media (min-width: 1416px) {
  .footer-menu__item {
    margin: 4px 19px;
    font-size: 16px;
  }
}

.footer-menu__item_active {
  filter: url(#overlay-blue);
  cursor: default;
}

.footer-menu__item_active:hover {
  text-decoration: none !important;
}

.footer__jurisdiction {
  position: relative;
  float: left;
  width: 100%;
  margin: 30px 0 0;
  font: 12px/1.2 open-sans-regular, sans-serif;
  text-align: center;
  color: #a3acc1;
}

.footer__jurisdiction p {
  margin-bottom: 1em;
}

@media (min-width: 744px) {
  .footer__jurisdiction {
    font-size: 13px;
  }
}

@media (min-width: 1000px) {
  .footer__jurisdiction {
    margin-top: 50px;
  }
}

@media (min-width: 1256px) {
  .footer__jurisdiction {
    font-size: 14px;
  }
}

@media (min-width: 1416px) {
  .footer__jurisdiction {
    margin-top: 70px;
  }
}

.footer__jurisdiction_mt {
  margin-top: 10px;
}

@media (min-width: 1000px) {
  .footer__jurisdiction_mt {
    margin-top: 20px;
  }
}

@media (min-width: 1416px) {
  .footer__jurisdiction_mt {
    margin-top: 20px;
  }
}

.footer__year {
  display: block;
  margin-top: 1em;
  font: 10px/1.2 open-sans-regular, sans-serif;
  color: #a3acc1;
}

@media (min-width: 744px) {
  .footer__year {
    font-size: 13px;
  }
}

@media (min-width: 1256px) {
  .footer__year {
    font-size: 14px;
  }
}

.footer img {
  font: 0/0 arial;
}

.footer-providers {
  position: relative;
  display: grid;
  grid-template: auto/repeat(2, 1fr);
  grid-gap: 8px;
  direction: rtl;
  width: 100%;
  padding: 30px 0 60px;
  border-top: 1px solid #1a243c;
  place-content: center;
}

.footer-providers_is-open {
  max-height: none;
}

.footer-providers_is-open .footer-providers__age-restriction,
.footer-providers_is-open .footer-providers__item,
.footer-providers_is-open .footer-providers__playattack {
  display: flex;
}

@media (min-width: 456px) {
  .footer-providers {
    grid-template: auto/repeat(3, 1fr);
  }
}

@media (min-width: 936px) {
  .footer-providers {
    grid-template: auto/repeat(5, 1fr);
    grid-gap: 12px;
  }
}

@media (min-width: 1256px) {
  .footer-providers {
    grid-template: auto/repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 14px;
  }
}

.footer-providers__age-restriction,
.footer-providers__item,
.footer-providers__playattack {
  display: none;
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 15px;
  border-radius: 5px;
  background: #082648;
}

.footer-providers__age-restriction:nth-child(-n + 6),
.footer-providers__item:nth-child(-n + 6),
.footer-providers__playattack:nth-child(-n + 30) {
  display: flex;
}

.footer-providers__item:hover,
.footer-providers__playattack:hover {
  box-shadow: 0 0 5px rgba(0, 235, 234, 0.7);
}

@media (min-width: 744px) {
  .footer-providers__age-restriction,
  .footer-providers__item,
  .footer-providers__playattack {
    height: 80px;
  }
}

.footer-providers__age-restriction img,
.footer-providers__item img,
.footer-providers__playattack img {
  vertical-align: middle;
  max-height: 100%;
  height: auto;
  width: auto;
}
.footer-providers__item:nth-of-type(5) {
  @media (max-width: 1302px) and (min-width: 936px) {
    grid-row-start: 2;
    grid-column-start: 5;
  }
}
.footer-providers__item:last-of-type {
  grid-row-start: 18;
  grid-column-start: 2;

  @media (min-width: 456px) {
    grid-row-start: 12;
    grid-column-start: 3;
  }
  @media (min-width: 936px) {
    grid-row-start: 6;
    grid-column-start: 6;
  }
  @media (min-width: 1256px) {
    grid-row-start: 6;
    grid-column-start: 6;
  }
}

.footer-providers__age-restriction {
  position: relative;
}

.footer-providers__age-restriction img {
  width: 45px;
  max-width: 100%;
  height: 45px;
  max-height: 100%;
}

.footer-providers__playattack {
  background: 0 0;
}

.footer-providers__playattack:hover {
  box-shadow: none;
  filter: drop-shadow(0 0 3px rgba(40, 190, 190, 0.7));
}

.footer-providers__show-more {
  position: absolute;
  padding: 10px 20px 10px 0;
  white-space: nowrap;
  color: #a3acc1;
  background: 0 0;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #00badc;
    &:before {
      border-top-color: #00badc;
    }
  }
}

@media (min-width: 744px) {
  .footer-providers__show-more {
    transform: none;
  }
}

.footer-providers__show-more:before {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  display: block;
  border: 6px solid transparent;
  border-top-color: #a3acc1;
}

.footer-providers__show-more_is-active {
  color: #00badc;
}

.footer-providers__show-more_is-active:hover {
  &:before {
    border-top-color: transparent;
  }
}

.footer-providers__show-more_is-active:before {
  top: 10px;
  border: 6px solid transparent;
  border-bottom-color: #00badc;
}

.footer-providers__item_endorphina {
  padding: 8px 15px;
}

@media (min-width: 744px) {
  .footer-providers__item_endorphina {
    padding: 15px;
  }
}

.footer-payments {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-top: 1px solid #1a243c;
  border-bottom: 1px solid #1a243c;
}

.footer-payments__item {
  position: relative;
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  align-items: center;
  height: 28px;
  margin: 4px 8px;
}
.footer-payments__item:after {
  background: #010c26;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  mix-blend-mode: color;
  position: absolute;
  top: 0;
  width: 100%;
}

@media (min-width: 744px) {
  .footer-payments__item {
    margin: 6px 12px;
  }
}

@media (min-width: 1256px) {
  .footer-payments__item {
    max-height: 40px;
    margin: 14px;
  }
}

.footer-payments__item img {
  position: relative;
  display: block;
  height: 80%;
  max-height: 100%;
  margin: auto;
}

@media (min-width: 744px) {
  .footer-payments__item img {
    height: 90%;
  }
}

@media (min-width: 1416px) {
  .footer-payments__item img {
    height: 100%;
  }
}

.footer-payments__item-bitcoin img {
  top: 8%;
}

.footer-payments__item-muchbetter img,
.footer-payments__item-neosurf img {
  max-height: 35px;
}

.footer-payments__item-coin-payments img {
  top: -20%;
}

@media (min-width: 744px) {
  .footer-payments__item-coin-payments img {
    top: auto;
    margin-top: -6%;
  }
}

@media (min-width: 1256px) {
  .footer-payments__item-coin-payments img {
    margin-top: -16%;
  }
}

@media (min-width: 1416px) {
  .footer-payments__item-coin-payments img {
    top: auto;
    margin-top: -20%;
  }
}

.footer-license {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.footer-license:empty {
  display: none;
}

.footer-license * {
  display: inline-block !important;
  min-width: 0 !important;
  max-width: none !important;
  overflow: visible !important;
}

.footer-license__curacao,
.footer-license__mga,
.footer-license__romania {
  width: 100%;
  margin: 6px;
}

.footer-license__curacao + .footer-providers__age-restriction,
.footer-license__curacao img,
.footer-license__mga + .footer-providers__age-restriction,
.footer-license__mga img,
.footer-license__romania + .footer-providers__age-restriction,
.footer-license__romania img {
  vertical-align: middle;
  width: auto !important;
  height: 30px !important;
  padding: 0;
}

@media (min-width: 744px) {
  .footer-license__curacao + .footer-providers__age-restriction,
  .footer-license__curacao img,
  .footer-license__mga + .footer-providers__age-restriction,
  .footer-license__mga img,
  .footer-license__romania + .footer-providers__age-restriction,
  .footer-license__romania img {
    height: 36px !important;
  }
}

@media (min-width: 1256px) {
  .footer-license__curacao + .footer-providers__age-restriction,
  .footer-license__curacao img,
  .footer-license__mga + .footer-providers__age-restriction,
  .footer-license__mga img,
  .footer-license__romania + .footer-providers__age-restriction,
  .footer-license__romania img {
    height: 40px !important;
  }
}

@media (min-width: 1416px) {
  .footer-license__curacao + .footer-providers__age-restriction,
  .footer-license__curacao img,
  .footer-license__mga + .footer-providers__age-restriction,
  .footer-license__mga img,
  .footer-license__romania + .footer-providers__age-restriction,
  .footer-license__romania img {
    height: 45px !important;
  }
}

.footer-license__romania {
  width: auto;
  margin: 0 10px;
}

.social-networks {
  align-items: center;
  display: flex;
  justify-content: center;
}

.social-networks__text {
  color: #a3acc1;
  font-size: 14px;
  margin-right: 16px;
}

.social-networks__list {
  align-items: center;
  display: flex;
}

.social-networks__item {
  margin-right: 8px;
}

.social-networks__item:last-child {
  margin-right: 0;
}

.social-networks__icon {
  transition: fill 0.25s ease;
}

.social-networks__link:hover .social-networks__icon {
  fill: #00badc;
}
.find-us {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 0;
  border-bottom: 1px solid #1a243c;
  @media (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .social-networks, .language-selector {
      margin: 0 10px 20px 10px;
    }
    .language-selector {
      width: 240px;
    }
  }
}

